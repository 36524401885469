import { firebase}  from 'gatsby-theme-firebase';
import { Typography, Link } from '@material-ui/core';
import {nanoid} from 'nanoid';

export const utcTime = () =>
{   
   return firebase.firestore.Timestamp.now().toMillis();
  //  console.log("created: " + created);
  //  const v = new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000);
  //  console.log("created:" , v);
  //   return  v;
}

export const utcTimePlus = (timeStamp) =>
{
    //console.log("utcTime: " + timeStamp);
 return timeStamp;
   // return  timeStamp + (new Date().getTimezoneOffset() * 60 * 1000);
}

export const utcTimeOrg = (timeStamp) =>
{
    //console.log("utcTime: " + timeStamp);
    return timeStamp;
   // return  timeStamp - (new Date().getTimezoneOffset() * 60 * 1000);
}

export function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return -1;  
       else if(a[property] < b[property])  
          return 1;  
   
       return 0;  
    }  
  }

export  const warpText = (itext) => {
   if(itext===undefined || itext===null){
     return "";
   }
   
   if(itext.length>0){ 
   return itext.substr(0,60)+"...";
   }else{ 
   return "";
   }
 }



 export function Copyright() {
   return (
     <Typography variant="body2" color="textSecondary" align="center">
       {'Copyright © '}
       <Link color="inherit" href={process.env.GATSBY_HOST}>
       {process.env.GATSBY_HOST}
       </Link>{' '}
       {new Date().getFullYear()}
       {'.'}
     </Typography>
   );
 }


 export const getLabel = (status: string) =>{
  
    const salesStatus =[
      {value: 'open',label: 'Open'},
      {value: 'cart',label: 'Cart'},
      {value: 'ordered',label: 'Ordered'},
      {value: 'processing',label: 'Processing'},
      {value: 'delivery',label: 'For Pickup'},
      {value: 'intransit',label: 'In Transit'},
      {value: 'delivered',label: 'Delivered'},
      {value: 'completed',label: 'Completed'},
      {value: 'canceled',label: 'Canceled'},
    ]

    let st =  salesStatus.find( ss => ss.value === status );
    
    return st.label;

 }

 export const getMDateToday = (date: number) =>{
      var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('');
 }

//  firebase.database().ref().child("Users").orderByChild('regdate')
//  .startAt("2019-01-05").endAt("2019-01-10 23:59:59");

 export const getMTransNumber = (date: number) =>{
  var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = ''+d.getFullYear(),
        hh = ''+d.getHours(),
        mm = ''+d.getMinutes(),
        second = ''+d.getSeconds();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (second.length < 2)
       second = '0' + second;
    if (hh.length < 2)
        hh = '0' + hh;
    if (mm.length < 2)
        mm = '0' + mm;
    
    let key =  [year.substring(2, 4), month, day,hh,mm,second].join('');

    return key;
}

export  enum ScreenView {
  STORE =0,
  ACCOUNT=1, 
  STORE_PRODUCT=2,
  WELCOME=3,
  HELP=4,
  CONTACTUS=5,
  ORDER_DIALOG=6,
  STORE_SETTING=7,
  SCHEDULING=21
}